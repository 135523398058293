$primary-purple: #38438f;
$secondary-purple: #2e3880;
$tertiary-purple: #232d63;
$quarternary-purple: #1d2657;

$primary-green: #41ba92;
$secondary-green: #4ab994;

$white: #ffffff;
$blue: #055bff;
$primary-blue: #2c6eda;
$hover-blue: #6392dc;
$dark-blue: #004c80;
$checked-blue: #005c99;
$blue-black: #003d66;
$gray: #617286;
$space-grey: #969595;
$charcoal-grey: #9d9c9c;
$dark-grey: #737073;
$light-grey: #cccfd4;
$green: #33ff00;
$red: #e91545;
$blackish-red: #f25c61;
$light-red: #f36e72;
$primary-alt: $red;

$container-border: 3px solid $primary-purple;
$container-radius: 30px;
$button-radius: 43px 35px 35px 0px;
