.echarts {
  width: 100%;
  margin-top: 88px;
  display: grid;
  grid-template-columns: calc(60% - 13px) calc(40% - 13px);
  column-gap: 26px;

  .echarts_line,
  .echarts_pie {
    border: $container-border;
    border-radius: $container-radius;

    .line_top,
    .pie_top {
      padding: 32px 32px 0;

      span {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.42;
        color: $primary-purple;
      }
    }

    .line_content,
    .pie_content {
      position: relative;
      width: 100%;
      height: 415px;
      padding-bottom: 10px;
      line-height: 415px;
      text-align: center;
      color: #a6a6a6;

      .no_data {
        color: $primary-purple;
      }
    }
  }
}

@media (max-width: 768px) {
  .echarts {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    row-gap: 24px;
  }
}

@media (max-width: 550px) {
  .echarts {
    margin-top: 0;
  }
}
