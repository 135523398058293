@import '~@/assets/css/_rtl-sass.scss';
@import './_theme';

$url: '~@/assets/uploads/vt/';

$main-color: #333333;

//login
$logo: $url + 'logo.png';
$logo2: $url + 'logo2.png';
$loading: $url + 'loading.png';

$main-b-color: rgb(255, 255, 255);
$box-shadow: 0 -9px 35px 0 rgba(0, 0, 0, 0.13);

$gradient-background: linear-gradient(to right, rgb(68, 0, 235), rgb(68, 236, 245));
$gradient-background-top: linear-gradient(rgb(68, 236, 245), rgb(68, 0, 235));
