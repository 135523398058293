.wrapper {
  transform: translateZ(0);

  .calendar_content {
    width: 100%;
    margin: 0 auto;

    .bottom {
      display: grid;
      grid-template-columns: calc(90% - 16px) calc(10% - 16px);
      column-gap: 32px;
      //dateRangePicker
      .calendar {
        @include rtl-sass-prop(padding-left, padding-right, 3%);
        margin-left: 0;
        margin-right: 0;
        display: inline-block;
      }
    }

    // 六個boxes
    .calendar_content_bottom {
      width: 100%;
      margin-top: 48px;

      ul {
        width: 100%;
        display: grid;
        grid-template-columns: 32% 32% 32%;
        column-gap: 24px;
        row-gap: 32px;

        li {
          background-color: $secondary-green;
          box-shadow: $box-shadow;
          border-radius: $container-radius;
          padding: 16px;
          display: grid;
          grid-template-columns: calc(50% - 10px) calc(50% - 10px);
          column-gap: 20px;
          cursor: pointer;

          .left {
            background-color: $secondary-green;
            height: 109px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .right {
            display: flex;
            align-items: center;

            > div {
              p {
                font-size: 32px;
                font-weight: bold;
                line-height: 1.38;
                color: $white;
              }

              span {
                font-size: 16px;
                font-weight: 200;
                line-height: 1.56;
                color: $white;
              }

              img {
                @include rtl-sass-value(float, right, left);
                width: 16px;
              }
            }
          }
        }
      }
    }
  }

  .tables {
    padding-top: 51px;
    padding-top: 51px;

    .table_list {
      margin-bottom: 40px;
    }

    .tables_top {
      display: grid;
      grid-template-columns: calc(50% - 15px) calc(50% - 15px);
      column-gap: 30px;

      .table_list_top {
        background-color: $primary-purple;
        padding: 0 24px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90px;
        position: relative;
        border-radius: 30px 30px 0 0;

        span {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.5;
          color: #ffffff;
        }
      }

      .table_list_content {
        width: 100%;
        height: 280px;

        /deep/ .el-table {
          border-radius: 0 0 30px 30px;
        }
      }
    }

    .tables_bottom {
      box-shadow: $box-shadow;
      padding: 33px 24px;
      margin-top: 30px;

      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.42;
        color: $primary-purple;
        display: inline-block;
      }

      > div {
        display: inline-block;

        img {
          width: 24px;
          margin-bottom: 5px;
          @include rtl-sass-value(margin-right, 16px, 40px);
          @include rtl-sass-value(margin-left, 40px, 16px);
        }

        .vtSupport {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.56;
          color: $primary-purple;
        }

        a,
        .liveChat {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.56;
          color: $primary-purple;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .wrapper .calendar_content .calendar_content_bottom ul li .left > div p {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    .calendar_content {
      .bottom {
        row-gap: 25px;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

        .calendar {
          margin-bottom: 25px;
        }

        .form_button {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .wrapper {
    .calendar_content {
      .calendar_content_bottom {
        margin-top: 24px;

        ul {
          grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        }
      }
    }

    .tables {
      padding-top: 24px;

      .tables_top {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

        .table_list_top span {
          font-size: 20px;
        }
      }

      .tables_bottom {
        .title {
          font-size: 20px;
          display: block;
          margin-bottom: 10px;
        }

        > div {
          display: block;

          img {
            @include rtl-sass-prop(margin-left, margin-right, 0);
          }
        }
      }
    }
  }
}

/* 首页表格样式开始 */

/* thead的樣式 */
/* th */
#home /deep/ .table_list_content .el-table th .cell {
  min-height: 68px;
}

/* 首页表格样式结束 */
